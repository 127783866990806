import { Injectable,Component, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from './search.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor() { }
}


// @Component({
//   templateUrl: '../_dialog/dialog-damaged.html'
// })
// export class DialogDamagedComponent {}

// @Component({
//   templateUrl: '../_dialog/dialog-defective.html'
// })
// export class DialogDefectiveComponent {}

// @Component({
//   templateUrl: '../_dialog/dialog-wrongitem.html'
// })
// export class DialogWrongItemComponent {}

@Component({
  templateUrl: '../_dialog/dialog-articolidarendere.html'
})
export class DialogArticoliDaRendereComponent {

  openListaResi : boolean = false;
  today : any ;
  resi : any = [];
  dataload : boolean = false;
  articoli_totali : number = 0;
  articoli_scaduti : number = 0;

  constructor (@Inject(MAT_DIALOG_DATA) public data, private dialog : MatDialog, private user : UserService) {}

  ngOnInit() : void {
    this.today = new Date().getTime();

    this.user.ResiObs.subscribe(res => {
      this.resi = res;
      this.dataload = true;
    })

    this.articoli_totali = this.data.articoli_totali;
    this.articoli_scaduti = this.data.articoli_scaduti;

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  open(){
    this.openListaResi = true;
  }

  close(){
    this.openListaResi = false;
  }

  oldSGN(dt : string) {

    var date = new Date(dt).getTime();

    if (date <= this.today) {
      return true;
    }
  }

}

@Component({
  templateUrl: '../_dialog/dialog-order-number.html'
})
export class DialogOrderNumberComponent {}

@Component({
  templateUrl: '../_dialog/dialog-specific-reserve.html'
})
export class DialogSpecificReserveComponent {}

@Component({
  templateUrl: '../_dialog/dialog-changepassword.html'
})
export class DialogChangePasswordComponent {

  username: string;
  oldPwd: string;
  newPwd: string;

  changePwdForm: FormGroup;

  constructor (private user: UserService, private api: ApiService, private fb : FormBuilder, private snackBar: MatSnackBar) {}

  ngOnInit(): void {

    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.username = res.description
      })
    
      this.changePwdForm = this.fb.group({
        'oldPassword': new FormControl('', [Validators.required]),
        'newPassword': new FormControl('', [Validators.required]),
        'confirmPassword': new FormControl('', [Validators.required]),
      });
    }

  onSubmit() {
    this.oldPwd = this.changePwdForm.get('oldPassword').value;
    this.newPwd = this.changePwdForm.get('newPassword').value;
      this.api.changePassword(this.oldPwd,this.newPwd)
        .subscribe(
          (res: any) => {
            this.openSnackBar('Password aggiornata con successo')
          },
          (err: any) => {
            this.openSnackBar('Errore : ' + err.error.userMessage)
        }
        )
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'x');
  }


}


@Component({
  templateUrl: '../_dialog/dialog-restorepassword.html'
})
export class DialogRestorePasswordComponent {

  username: string;
  email: string;
  restorePwdForm: FormGroup;

  constructor (private user: UserService, private api: ApiService, private fb : FormBuilder, private snackBar: MatSnackBar) {}

  ngOnInit(): void {

    this.restorePwdForm = this.fb.group({
      'username': new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)])
    });

  }

  onSubmit() {
    this.username = this.restorePwdForm.get('username').value;
    this.email = this.restorePwdForm.get('email').value;
      this.api.restorePwd(this.username,this.email)
      .subscribe(
      (res: any) => {
        this.openSnackBar('Password ripristinata con successo. Controlla la tua casella mail')
      }
    )
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'x');
  }


}



@Component({
  templateUrl: '../_dialog/dialog-searchcustomer.html',
  styles: ['.bold:hover {font-weight: 900;}']
})
export class DialogSearchCustomerComponent {

  cli: string;
  searchCustomerForm: FormGroup;
  result = [];
  

  constructor (private user: UserService, private api: ApiService, private fb : FormBuilder, private snackBar: MatSnackBar, private dialog: MatDialog) {}

  ngOnInit(): void {

    this.searchCustomerForm = this.fb.group({
      'cliente': new FormControl('', [Validators.required])
    });

  }

  onSubmit() {
    this.cli = this.searchCustomerForm.get('cliente').value;
      this.api.searchCustomers(this.cli)
      .subscribe(
      (res: any) => {
        this.result = res.data;
      },
      (err: any) => {
        this.openSnackBar('Errore : ' + err.error.userMessage)
    }
    )
  }


  openSnackBar(message: string) {
    this.snackBar.open(message, 'x');
  }

  onClick(i) {
    this.user.pushClienteB2B(this.result[i].columns[2].value, this.result[i].columns[1].value);
    const dialogRef = this.dialog.closeAll();
  }

}

@Component({
  templateUrl: '../_dialog/dialog-esitovettore.html'
})
export class DialogEsitoVettoreComponent {

  esitoVettoreRifiutaForm: FormGroup;
  esitoVettoreApprovaForm: FormGroup;

  motivo : string = '';
  spedizione : string = '';
  rimborso : number;
  commento : string = '';
  esito : string = '';
  statoRimborso : string = '';
  rimborsocontratto : number;

  loaded = false;

  constructor (@Inject(MAT_DIALOG_DATA) public data, private user: UserService, private api: ApiService, private fb : FormBuilder, private snackBar: MatSnackBar, private search : SearchService, private dialog: MatDialog) {}

  ngOnInit(): void {

    this.esito = this.data.esito;
    this.esitoVettoreRifiutaForm = this.fb.group({
        'motivo': new FormControl('', [Validators.required])        
    });
    this.esitoVettoreApprovaForm = this.fb.group({
          'rimborsocontratto' : new FormControl({value: this.data.rimborsocontratto, disabled: true}),
          'rimborso': new FormControl('',[Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
          'spedizione': new FormControl('',[Validators.required,  Validators.pattern(/^[0-9]*$/)]),
          'commento': new FormControl('')
    });
    this.loaded = true;
    }

  onSubmit() {
    if (this.esito === 'rifiuta') {
      this.motivo = this.esitoVettoreRifiutaForm.get('motivo').value;
      this.statoRimborso = '';
      this.api.caricaProfile(this.data.id)
      .subscribe((res:any) => {
        res.fields.forEach((el, index) => {
          if (el.name === 'TESTO84_2') {
            res.fields[index].value = this.motivo;
          }
          if (el.name === 'COMBO92_2') {
            res.fields[index].value = 'Rifiutata';
          }
        });
        this.api.aggiornaProfile(this.data.id,res)
         .subscribe((res:any) => {
            //avvia WF
            this.api.AvviaWFVerificaVettore(this.data.id)
            .subscribe((res:any) => {
            })
         })
      })

    }
    if (this.esito === 'approva') {
      this.rimborso = this.esitoVettoreApprovaForm.get('rimborso').value;
      this.commento = this.esitoVettoreApprovaForm.get('commento').value;
      this.spedizione = this.esitoVettoreApprovaForm.get('spedizione').value;
      this.statoRimborso = 'DA RICEVERE'
      this.api.caricaProfile(this.data.id)
      .subscribe((res:any) => {
        res.fields.forEach((el, index) => {
          if (el.name === 'NUMERIC82_2') {
            res.fields[index].value = this.rimborso;
          }
          if (el.name === 'TESTO84_2') {
            res.fields[index].value = this.commento;
          }
          if (el.name === 'COMBO92_2') {
            res.fields[index].value = 'Approvata';
          }
          if (el.name === 'TESTO66_2') {
            res.fields[index].value = this.spedizione;
          }
          if (el.name === 'COMBO38_2') {
            res.fields[index].value = this.statoRimborso;
          }
        });
        this.api.aggiornaProfile(this.data.id,res)
        .subscribe((res:any) => {
            //avvia WF
            this.api.AvviaWFVerificaVettore(this.data.id)
            .subscribe((res:any) => {
            })
        })
      })
    }


    this.search.case.forEach((c,index) => {
      if (c[0] === this.data.id) {
        this.search.case.splice(index, 1);
        const dialogRef = this.dialog.closeAll();
      }        
    });
    
  }

  

  openSnackBar(message: string) {
    this.snackBar.open(message, 'x');
  }

}


@Component({
  templateUrl: '../_dialog/dialog-undocase.html'
})
export class DialogUndoCaseComponent {

  id : number;
  sgn : string;
  del = []; //Array con profili da cancellare
  counter = 0;

  loaded = false;

  constructor (@Inject(MAT_DIALOG_DATA) public data, private api: ApiService, private search : SearchService, private dialog: MatDialog) {}

  ngOnInit(): void {

    this.id = this.data.id;
    this.sgn = this.data.sgn;

    this.loaded = true;

    }

  confirm() {
    //Valorizzo Array 'del' con id della Segnalazione
    this.del.push(this.id);
            //Cerco gli articoli legati alla Segnalazione
            this.api.getArticoliDaSIDSegnalazione(this.id)
            .subscribe((res:any) => {
              res.data.forEach(art => {
                this.del.push(art[0]);
              });
              //console.log(this.del,'sgn + articoli')
              //Arresto ogni WF attivo sui documenti
                this.del.forEach(d => {
                  this.counter++;
                  //console.log('Contatore:' + this.counter)
                  this.api.AvviaWFFermaWF(d)
                    .subscribe((res:any) => {})
                  //Se ho fermato i WF di tutti i profili
                  if (this.del.length === this.counter) {
                    //console.log('Totale Profili: ' + this.del.length, 'Contatore: ' + this.counter);
                    //console.log('mi fermo 10 secondi');
                    setTimeout(() => {
                      //console.log('riparto');
                      //Elimino tutti i profili dell'array 'del'
                        this.del.forEach(_delete => {
                        //this.api.DeleteProfile(_delete)
                        //.subscribe(res => {
                          console.log('eliminato ' + _delete);
                        //});
                        });
                      },10000);
                    }
            });
          })

    this.search.case.forEach((c,index) => {
      if (c[0] === this.data.id) {
        this.search.case.splice(index, 1);
        const dialogRef = this.dialog.closeAll();
      }        
    });
  }

  close(){
    const dialogRef = this.dialog.closeAll();
  };

}