
<div class="nessuna-segnalazione" *ngIf="!reso.length">
    Selezionare un reso da organizzare
</div>

<div *ngIf="reso.length">
    <div *ngFor="let a of articoli">
        {{a[0]}}
    </div>

    <hr>

    <div class="row">
        <div class="col-4">
            Numero colli
            <input type="text">
        </div>
        <div class="col-4">
            Peso colli
            <input type="text">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            Dimensione massima (A * L * P)<br>
            <input type="text" placeholder="Altezza"> * <input type="text" placeholder="Lunghezza"> * <input type="text" placeholder="Profondità">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            <div class="float-right">
                <input (click)="reset()" class="btn btn-warning" value="ANNULLA">
                &nbsp;
                <input (click)="organizza()" class="btn btn-success" value="ORGANIZZA">
            </div>
        </div>
    </div>

</div>