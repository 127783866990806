<div class="row">
	<div class="col-12">
    <h2 mat-dialog-title>Ripristina password</h2>
		<form class="main" id="restorePwdForm" [formGroup]="restorePwdForm" (ngSubmit)="onSubmit()">
			<div class="form-row align-items-center">
				<div class="col-md-12">
				  <mat-dialog-content class="mat-typography"> 
            <h3>Utente</h3><input type="text" class="form-control" placeholder="Utente" formControlName="username" id="username" #username><br>           
            <h3>E-mail</h3><input type="text" class="form-control" placeholder="E-mail" formControlName="email" id="email" #email><br>          
          </mat-dialog-content>
				</div>
          <mat-dialog-actions align="center">
            <div class="bottoni-invio">
              <button type="submit" class="btn btn-primary mr-4" form="restorePwdForm" [disabled]="restorePwdForm.invalid">Invia</button>
              <button mat-dialog-close class="btn btn-primary">Chiudi</button>
            </div>
          </mat-dialog-actions>
        </div>
      </form>
    </div>
  </div>
