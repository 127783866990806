<div class="row">
	<div class="col-12">
    <h2 mat-dialog-title>Ripristina password</h2>
		<form class="main" id="searchCustomerForm" [formGroup]="searchCustomerForm" (ngSubmit)="onSubmit()">
			<div class="form-row align-items-center">
				<div class="col-md-12">
				  <mat-dialog-content class="mat-typography"> 
            <h3>Cliente</h3>
            <input type="text" class="form-control" formControlName="cliente" id="cliente" #cliente autocomplete="off"><br>    
          </mat-dialog-content>
          <mat-dialog-actions align="center">
            <div class="bottoni-invio">
              <button type="submit" class="btn btn-primary mr-4" form="searchCustomerForm" [disabled]="searchCustomerForm.invalid">Cerca</button>
              <button mat-dialog-close class="btn btn-primary">Chiudi</button>
            </div>
          </mat-dialog-actions>
          <mat-dialog-content class="mat-typography"> 
            <div class="bold" *ngFor="let r of result; let i = index" (click)=onClick(i)>
              [{{r.columns[2].value}}] {{r.columns[1].value}}
           </div>         
          </mat-dialog-content>
        </div>
        </div>
      </form>
    </div>
  </div>