import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Articolo,ProfiloArticolo } from '../../../_models/app.model';
import { CsvService } from '../../../_services/csv.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogOrderNumberComponent } from '../../../_services/dialog.service';
import { FieldValidator, NoSpaceValidator } from '../../../_helpers/forms.validator';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  articoli = [];

  articolo: Articolo;
  cercato: boolean = false;
  trovato: boolean = false;
  articoloPN: string = '';

  outputArray:Articolo[] = [];  


  constructor(private csv: CsvService, private fb : FormBuilder, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.articoli = this.csv.articoli;
  }

  searchForm = this.fb.group({
    'art': new FormControl('', [Validators.required])
  });

  itemsForm = this.fb.group({
    'qty': new FormControl('', [Validators.required, Validators.min(1)]),
    'numord': new FormControl('', [Validators.required, NoSpaceValidator.cannotContainSpace, FieldValidator.sevenchars, Validators.pattern(/^[0-9]*$/), Validators.minLength(7),Validators.maxLength(7)])
  });

  get f(){
    return this.itemsForm.controls;
  }


  cercaArticolo(value: string) {
    this.articoloPN = this.searchForm.get('art').value
    this.outputArray.length = 0;
    this.cercato = false;
    this.csv.getArticolo(value)
      .subscribe((res: any) => {
          for (let element in res) {            
            this.outputArray.push({  
              PN: res[element].PN,
              DESCRIZIONE: res[element].DESCRIZIONE,
              FORNITORE: res[element].FORNITORE,
              PESO: res[element].PESO,
              STK: res[element].STK,
              BUYER: res[element].BUYER
    });  
}  
        if (this.outputArray.length) {
          this.articolo = this.outputArray[0];
          this.trovato = true;
          this.cercato = true;
        } else {
          this.trovato = false;
          this.cercato = true;
        }
      })
  }

  addArticolo(art : Articolo) {
    let artsgn : ProfiloArticolo = art;
    artsgn.QTY = this.itemsForm.get('qty').value;
    artsgn.NUMEROORDINE = this.itemsForm.get('numord').value;
    artsgn.ORIGINE = 'ORDINATO';
    this.csv.articoli.push(Object.assign({}, artsgn));
  }

  removeArticolo(index) {
    this.csv.articoli.splice(index,1);
  }

  resetSearch() {
    this.itemsForm.reset();
    this.searchForm.reset();
    this.csv.articoli.length = 0;
    this.cercato = false;
    //(<HTMLInputElement>document.getElementById("art")).value = '';
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogOrderNumberComponent);
  }

}
