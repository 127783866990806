import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import {TokenInterceptor} from './_helpers/token.interceptor';

import { AppComponent } from './app.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { NewCaseComponent } from './containers/newcase/newcase.component';
import { HeaderComponent } from './containers/header/header.component';

import { AppRoutes } from './_helpers/app.routes';
import { DamagedComponent } from './containers/newcase/forms/damaged/damaged.component';
import { DefectiveComponent } from './containers/newcase/forms/defective/defective.component';
import { WrongitemComponent } from './containers/newcase/forms/wrongitem/wrongitem.component';
import { LoginComponent } from './containers/login/login.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ItemsComponent } from './containers/newcase/items/items.component';
import { ItemsOrderedComponent } from './containers/newcase/items-ord/items-ord.component';
import { ItemsReceivedComponent } from './containers/newcase/items-rec/items-rec.component';
import { PendingComponent } from './containers/dashboard/pending/pending.component';
import { ApprovedComponent } from './containers/dashboard/approved/approved.component';
import { RejectedComponent } from './containers/dashboard/rejected/rejected.component';
import { PhotosComponent } from './containers/newcase/photos/photos.component';
import { Photos2Component } from './containers/newcase/photos/photos2.component';
import { Photos3Component } from './containers/newcase/photos/photos3.component';
import { DndDirective } from './_directives/dnd.directive';
import { ProgressComponent } from './containers/newcase/photos/progress/progress.component';
import { UserComponent } from './containers/dashboard/user/user.component';
import { NavbarComponent } from './containers/navbar/navbar.component';
import { ListCaseComponent } from './containers/dashboard/listcase/listcase.component';
import { CaseComponent } from './containers/dashboard/case/case.component';
import { SearchComponent } from './containers/dashboard/search/search.component';

import { ToastrModule } from 'ngx-toastr';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { FilePickerModule } from  'ngx-awesome-uploader';

import { DialogChangePasswordComponent, DialogRestorePasswordComponent, DialogSearchCustomerComponent, DialogEsitoVettoreComponent, DialogUndoCaseComponent, DialogArticoliDaRendereComponent } from './_services/dialog.service';
import { AttachmentsComponent } from './containers/dashboard/case/attachments/attachments.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { SendBackComponent } from './containers/send-back/send-back.component';
import { SbComponent } from './containers/send-back/sb/sb.component';
import { PendingChangesGuard } from './_helpers/pending-changes.guard';
import { BoxComponent } from './containers/send-back/box/box.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NewCaseComponent,
    HeaderComponent,
    DamagedComponent,
    DefectiveComponent,
    WrongitemComponent,
    LoginComponent,
    ItemsComponent,
    ItemsOrderedComponent,
    ItemsReceivedComponent,
    PendingComponent,
    ApprovedComponent,
    RejectedComponent,
    PhotosComponent,
    Photos2Component,
    Photos3Component,
    DndDirective,
    ProgressComponent,
    UserComponent,
    NavbarComponent,
    ListCaseComponent,
    CaseComponent,
    SearchComponent,
    DialogChangePasswordComponent,
    DialogRestorePasswordComponent,
    DialogSearchCustomerComponent,
    DialogEsitoVettoreComponent,
    DialogUndoCaseComponent,
    DialogArticoliDaRendereComponent,
    AttachmentsComponent,
    LayoutComponent,
    SendBackComponent,
    SbComponent,
    BoxComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    NgxGalleryModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    FilePickerModule,
    ToastrModule.forRoot()
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, DatePipe, ItemsComponent, PendingChangesGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }