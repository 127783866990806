import { Component, OnInit, Input } from '@angular/core';
import { SearchService } from 'src/app/_services/search.service';
import { ApiService } from 'src/app/_services/api.service';
import { UserService } from 'src/app/_services/user.service';
import {MatDialog} from '@angular/material/dialog';

import {DialogEsitoVettoreComponent,DialogUndoCaseComponent} from '../../../_services/dialog.service';
import { Segnalazione } from 'src/app/_models/app.model';


@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss']
})
export class CaseComponent implements OnInit {

  @Input() id: number;
  case: any;
  click: boolean = false;
  articoli: any;
  allegati: boolean = false;
  Vettore : boolean ;

  constructor(private search: SearchService, private api: ApiService, private user : UserService, private dialog: MatDialog) { }

  ngOnInit(): void {

  this.Vettore = this.user.Vettore;
  this.case = this.search.case[this.id];
    if(!this.Vettore) {
      this.api.getArticoliDaSIDSegnalazione(this.case[0])
        .subscribe((res: any) => {
          this.articoli = res.data;
        })
    }
  }

  clicked() {
    this.click = !this.click;
  }

  esitoVettore(esito : string, id : number, rimborsocontratto : number) {
    if (esito === 'rifiuta') {
      const dialogRef = this.dialog.open(DialogEsitoVettoreComponent, {
        width : '530px',
        height : '400px',
        data : {
          id : id,
          esito : esito,
          rimborsocontratto : rimborsocontratto
        }
      })
    }
    if (esito === 'approva') {
      const dialogRef = this.dialog.open(DialogEsitoVettoreComponent, {
        width : '530px',
        height : '700px',
        data : {
          id : id,
          esito : esito,
          rimborsocontratto : rimborsocontratto
        }
      })
    }
  }

  undoSGN(id : number, sgn : string) {
    const dialogRef = this.dialog.open(DialogUndoCaseComponent, {
      width : '400px',
      height : '250px',
      data : {
        id : id,
        sgn : sgn
      }
    })
  };


//END
}
