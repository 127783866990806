<div class="row">
	<div class="col-12">
    <h2 mat-dialog-title>Cambia password</h2>
		<form class="main" id="changePwdForm" [formGroup]="changePwdForm" (ngSubmit)="onSubmit()">
				  <mat-dialog-content class="mat-typography"> 
            <h3>Utente : <b>{{username}}</b></h3>
            <hr>
            <h3>Password attuale</h3><input type="password" class="form-control" placeholder="Password attuale" formControlName="oldPassword" id="oldPassword" #oldPassword><br>           
            <h3>Nuova password</h3><input type="password" class="form-control" placeholder="Nuova password" formControlName="newPassword" id="newPassword" #newPassword><br>
            <h3>Conferma password</h3><input type="password" class="form-control" placeholder="Conferma password" formControlName="confirmPassword" id="confirmPassword" #confirmPassword>
          </mat-dialog-content>
          <mat-dialog-actions align="center">
            <div class="bottoni-invio">
              <button type="submit" class="btn btn-primary mr-4" form="changePwdForm" [disabled]="changePwdForm.invalid">Invia</button>
              <button mat-dialog-close class="btn btn-primary">Chiudi</button>
            </div>
          </mat-dialog-actions>
      </form>
    </div>
  </div>