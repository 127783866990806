<!-- <app-header></app-header> -->
<app-navbar></app-navbar>
pending
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div *ngFor="let p of pending">
                {{p[1]}}
            </div>
            <br>
            <hr>
            <button class="float-right" (click)="backClicked()">Indietro</button>
        </div>
    </div>
</div>