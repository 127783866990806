<!-- <app-header></app-header> -->
<app-navbar></app-navbar>

<div class="container">
	<div class="row">
		<div class="col-12">
			<h2 class="titolo-nuovasegnalazione">
				<!-- <span class="titolo-light">Inserisci nuova segnalazione:</span> -->
				<!-- <br /> -->
				Danno da trasporto
				<!-- <img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog()"> -->
			</h2>
			


		</div>
		<div class="col-12"><!-- col MAIN FORM -->
			<form *ngIf="dataload" class="main" id="damagedForm" [formGroup]="damagedForm" (ngSubmit)="onFormSubmit($event)">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 col-md-6">
							<label>Cliente</label><br />
								<b><input class="form-control" formControlName="cliente" name="cliente" #cliente></b>			
								<button id="search" class="btn btn-warning" *ngIf="!customerB2B">Cerca</button>		
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12 col-md-3">
							<label>Numero DDT<sup class="red"> *</sup></label><sup><img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog('DDT')"></sup><br />
							<b><input class="form-control" formControlName="numeroDDT" #numeroDDT maxlength="6"></b>
							<small class="text-danger" *ngIf="f.numeroDDT.errors?.cannotContainSpace">Il numero DDT non può contenere spazi</small>
							<small class="text-danger" *ngIf="f.numeroDDT.errors?.notsixchars">Il numero DDT deve essere di 6 caratteri</small>
						</div>
						<div class="col-sm-12 col-md-3">
							<label>Data DDT<sup class="red"> *</sup></label><br />
							<input class="form-control" type="date" formControlName="dataDDT" #dataDDT>
							<small class="text-danger" *ngIf="damagedForm.errors?.misMatch || damagedForm.errors?.DateDDTBeforeToday">Data DDT non corretta</small>
						</div>
						<div class="col-sm-12 col-md-3">
								<label>Data consegna<sup class="red"> *</sup></label><br />
								<input class="form-control" type="date" formControlName="dataConsegna" #dataConsegna>
							<small class="text-danger" *ngIf="damagedForm.errors?.misMatch || damagedForm.errors?.DateConsegnaBeforeToday">Data di consegna non corretta</small>
						</div>
					</div>
					
					<hr class="form-separatore">
					
					<div class="row">
						<div class="col-12">						
							<label>Descrizione difetto</label><br />
							<textarea class="form-control" formControlName="note"></textarea>
						</div>
					</div>
					
					<hr class="form-separatore">
					
					<div class="row">
						<div class="col-12">
							<label class="form-check-label">Riserva specifica<sup class="red"> *</sup></label> &nbsp;<input type="checkbox" formControlName="riserva" #riserva> &nbsp;
							<sup><img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog('RiservaSpecifica')"></sup>
							<app-photos3 *ngIf="damagedForm.controls.riserva.valid"></app-photos3>
						</div>
					</div>
					<br><br>
					
					<hr class="form-separatore">
					
					Articolo<sup class="red"> *</sup>
					<app-items></app-items>
					
					
					<hr class="form-separatore">
					Immagini imballo danneggiato
					<sup class="red"> *</sup>
					<app-photos></app-photos>
					
					<hr class="form-separatore">
					Immagini ricambio danneggiato
					<sup class="red"> *</sup>
					<app-photos2></app-photos2>
					
					<hr>
					<sup class="red"> *</sup> Informazioni necessarie
					
				</div>
				
				<div class="bottoni-invio">
					<button type="submit" id="submit" class="btn btn-primary mr-4" form="damagedForm" [disabled]="damagedForm.invalid || !cli || !itemsArray.length || (photosArray.length < 1) || (photos2Array.length < 1)">Apri</button>
					<button type="reset" class="btn btn-primary" (click)="backClicked()">Indietro</button>
				</div>

				<!-- <div *ngIf="numeroSGN && added">
					Segnalazione creata. Il numero della segnalazione è {{numeroSGN}}
				</div> -->
				
			</form>
		</div><!-- /col MAIN FORM -->
	</div><!-- /row -->
</div><!-- /container -->