import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements OnInit {

  box : any = [];

  constructor(private fb : FormBuilder) { }

  ngOnInit(): void {
    
  }

  boxForm = this.fb.group({
    'peso': new FormControl('', [Validators.required]),
    'altezza': new FormControl('', [Validators.required]),
    'lunghezza': new FormControl('', [Validators.required]),
    'profondità': new FormControl('', [Validators.required])
  });

  get f(){
    return this.boxForm.controls;
  }

  onFormSubmit() {

  }

}
