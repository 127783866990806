import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
import { DialogRestorePasswordComponent } from '../../_services/dialog.service';

import { config,version } from '../../_helpers/config';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ToastService } from '../../_services/toast.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  v = version;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private user: UserService, private dialog : MatDialog, private toast : ToastService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.authService.login(
      {
        username: this.f.username.value,
        password: this.f.password.value,
        clientId: `${config.clientId}`,
        clientSecret:`${config.clientSecret}`

      }
    )
    .subscribe(
      success => {
      // if (success) {
        this.router.navigate(['/app']);
      },
      err => {
          //console.log(err);
          //this.toast.showError('Errore : ' + err.error.userMessage);
      }
        //)
      //}
    //}
    )
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogRestorePasswordComponent);
  }



}