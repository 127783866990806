<!-- <app-header></app-header> -->
<app-navbar *ngIf="dataload" [Vettore]="Vettore"></app-navbar>

<div *ngIf="dataload" class="container">
    <div class="row">
        <div class="col-12">
            <div class="nessuna-segnalazione" *ngIf="!case.length">
                Nessuna segnalazione
            </div>
            <div *ngFor="let c of case; let i = index">
                <app-case [id]="i" [Vettore]="Vettore"></app-case>
            </div>
        </div>
    </div>
</div>