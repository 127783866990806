import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ApiService } from '../../../../_services/api.service';
import { CsvService } from '../../../../_services/csv.service';
import { UserService } from '../../../../_services/user.service';

import { Segnalazione, ProfiloCreato, Profilo } from '../../../../_models/app.model';
import { DialogSearchCustomerComponent,DialogOrderNumberComponent } from '../../../../_services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadService } from '../../../../_services/fileupload.service';

import {DateDDTValidator, DateValidator, FieldValidator, NoSpaceValidator} from '../../../../_helpers/forms.validator';
import { ToastService } from '../../../../_services/toast.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-wrongitem',
  templateUrl: './wrongitem.component.html',
  styleUrls: ['./wrongitem.component.scss']
})
export class WrongitemComponent implements OnInit, OnDestroy {

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.canChangePage;
  }

  canChangePage : boolean = false;
  sgn: Segnalazione;
  numeroSGN: string = '';
  sidSGN: number;
  art: string;
  dataload:boolean = false;
  cli: string = '';
  codicecliente : string = '';
  uid: string;
  groupB2B = [];
  customerB2B: boolean = false;

  added: boolean = false;

  itemsArrayOrd: any[];
  itemsArrayRec: any[];
  photosArray: any[];

  constructor(private router : Router, private user: UserService, private fb: FormBuilder, private api: ApiService, private csv: CsvService, private location: Location, private dialog: MatDialog, private toast: ToastService, private fileupload: FileUploadService) { }

  ngOnInit(): void {
    this.csv.articoliord.length = 0;
    this.csv.articolirec.length = 0;
    this.user.clienteB2BObs.subscribe((cliente:any) => {
      this.cli = cliente.cliente;
      this.codicecliente = cliente.codice;
      this.wrongitemForm.controls.cliente.setValue(this.cli);
    })
    this.itemsArrayOrd = this.csv.articoliord;
    this.itemsArrayRec = this.csv.articolirec;
    this.photosArray = this.fileupload.files3;
    this.user.getUserInfo()
    .subscribe((res: any) => {
      this.uid = res.user
      this.user.getB2BGroup()
      .subscribe((group: any) => {
        this.groupB2B = group;
        this.groupB2B.forEach((el:any) => {
          if (el.user === this.uid) {
            this.customerB2B = true;
          }
        });
        if (this.customerB2B) {
          this.cli = res.completeDescription;
          this.codicecliente = res.description;
          this.wrongitemForm.controls.cliente.setValue(this.cli);
        }
        this.dataload = true;
      })
    })
  }

  ngOnDestroy(): void {
    this.itemsArrayOrd.length = 0;
    this.itemsArrayRec.length = 0;
    this.photosArray.length = 0;
    this.user.pushCliente('','');
    this.user.pushClienteB2B('','');
  }

  backClicked() {
    this.location.back();
  }

  wrongitemForm = this.fb.group({
    'cliente': new FormControl({value : '', disabled : true},[Validators.required]),
    'numeroDDT': new FormControl('', [Validators.required, NoSpaceValidator.cannotContainSpace, Validators.maxLength(6), Validators.pattern(/^[0-9]*$/)]),
    'dataDDT': new FormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]),
    // 'dataConsegna': new FormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]),
    'note': new FormControl('')
  }
   , {validator: [DateDDTValidator]}
  );

  get f(){
    return this.wrongitemForm.controls;
  }

  onFormSubmit(): void {

    var buttonName = document.activeElement.getAttribute("id");

    if (buttonName === 'submit') {

    this.csv.articoli = [ ...this.csv.articoliord, ...this.csv.articolirec];

    this.sgn = {NumeroDDT: this.wrongitemForm.get('numeroDDT').value, DataDDT: this.wrongitemForm.get('dataDDT').value, DataConsegna: '', Tipologia: 'Articolo/Quantità errati', Note: this.wrongitemForm.get('note').value}

    this.api.addCase(this.sgn, this.cli, this.codicecliente, this.photosArray)
      .subscribe((res: ProfiloCreato) => {
        this.numeroSGN = res.internalProtocolNumber;
        this.sidSGN = res.docNumber;
        this.csv.articoli.concat(this.csv.articoliord, this.csv.articolirec);
        this.csv.articoli.forEach((id, index) => {
          this.api.addItems(this.cli, this.numeroSGN, this.sidSGN, index, id.ORIGINE)
          .subscribe((res: ProfiloCreato) => {
            })
        });
        this.api.caricaProfile(this.sidSGN)
                    .subscribe((res: Profilo) => {
                      for (let el of res.fields) {
                        if (el.name === 'NUMERIC15_2') {  
                          el.value = this.csv.articoli.length.toString();
                        }
                      }
                  this.api.aggiornaProfile(this.sidSGN, res)
                  .subscribe((res: any) => {
                    //this.fileupload.FileUpload(this.sidSGN)
                    this.itemsArrayOrd.length = 0;
                    this.itemsArrayRec.length = 0;
                    this.added = true;
                    this.wrongitemForm.reset();
                     if (this.customerB2B) {
                      this.wrongitemForm.controls.cliente.setValue(this.cli);
                    }
                    //window.scroll(0,0);
                    this.canChangePage = true;
                    this.router.navigate(['/app/newcase']);
                    this.toast.showSuccess('Segnalazione creata (' + this.numeroSGN + ')')
                  })
                })
        })
      }
      if (buttonName === 'search') {
        this.openSearch();
      }
  }

  openDialog(tipo: string) {
    if (tipo === 'DDT') {
     const dialogRef = this.dialog.open(DialogOrderNumberComponent);
    }
  }

  openSearch() {
    const dialogRef = this.dialog.open(DialogSearchCustomerComponent,{
        height: '500px',
        width: '800px',
      });
  }


}