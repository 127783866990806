import { Injectable } from '@angular/core';
import { Observable,forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http';
import { config } from '../_helpers/config';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  files: any[4] = [];
  files2: any[4] = [];
  files3: any[1] = [];

  files_def: any[10] = [];

  constructor (private http: HttpClient) {}
  
//   FileUpload(sid: number) {
//   //concat 3 arrays
//     this.files_def = this.files.concat(this.files2, this.files3)

//   // attach files
//   const observables: Observable<any>[] = [];
//   for (const fileToUpload of this.files_def) {
//     observables.push(this.attachFileToProfile(fileToUpload, sid, ''));
//   }
//   if (observables.length === 0) {
//     this.files_def.length = 0;
//     //this.spinnerService.stop(spinnerRef);
//     //this.location.back();
//   } else {
//     forkJoin(observables).subscribe(responseList => {
//       //this.spinnerService.stop(spinnerRef);
//       //this.location.back();
//     });
//   }
// }

// attachFileToProfile(fileToUpload, docNumber, comment): Observable<any> {
// const formData: FormData = new FormData();
// formData.append('file', fileToUpload, fileToUpload.name);
// return this.http.post(config.apiUrl + '/api/Buffer/insert', formData)
//   .pipe(
//     mergeMap((data: any) => {
//       const insertEndpoint = config.apiUrl + '/api/Attachments/InsertExternal/' + data[0] + '/' + docNumber;
//       return this.http.post(insertEndpoint, comment);
//     })
//   );
// }

UploadFileToBuffer(fileToUpload): Observable<any> {
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);

  return this.http.post(config.apiUrl + '/api/Buffer/insert', formData);
}


}
