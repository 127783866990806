import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input("Vettore")
  Vettore : boolean = false;

  resi = 0;
  dataload : boolean = false;
  
  constructor(private user: UserService) { }
    
  ngOnInit(): void {

      this.user.ResiObs
        .subscribe(res => {
          this.resi = res.length;
          this.dataload = true;
      })

  }

}
