<div class="container">
	<div class="row">
		<div class="col-12">
			<div class="ricerca">
				<div class="input-group mb-3">
					<input type="text" class="form-control" placeholder="Inserisci codice articolo" aria-label="Recipient's username" aria-describedby="Ricerca" #art [(ngModel)]="articolo">
					<div class="input-group-append">
						<button class="btn btn-info" type="button" id="button-addon2" (click)="searchSGNbyItems(art.value)" [disabled]="!articolo">Cerca</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

