<div class="container">
<div class="row">
	<div class="col-sm-12">
		<form class="main" id="searchForm" [formGroup]="searchForm" (ngSubmit)="cercaArticolo(art.value)">
			<div class="form-row align-items-center">
				<div class="col-md-4">
				  <label class="sr-only" for="art">Name</label>
				  <input type="text" class="form-control" placeholder="Articolo" formControlName="art" id="art" #art>
				</div>
				<div class="col-md-6">
				  <button type="submit" class="btn btn-primary" [disabled]="searchForm.invalid">Cerca</button>
				<button type="reset" class="btn btn-primary ml-2" (click)="resetSearch()">Reset</button>
				</div>
			  </div>
			</form>
		<div *ngIf="cercato == true && trovato == false">
			Articolo non trovato - {{articoloPN}}
		</div>
	<form class="main" id="itemsForm" [formGroup]="itemsForm" (ngSubmit)="addArticolo(articolo)">
		<div *ngIf="cercato == true && trovato == true">
			Articolo : {{articolo.PN}}
			<br>
			Descrizione : {{articolo.DESCRIZIONE}}
			<br><br>
			Quantità <input type="number" formControlName="qty" id="qty" #qty min="0">
			N. ordine <input type="text" formControlName="numord" id="numord" #numord maxlength="7" autocomplete=off>
			<sup><img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog()"></sup>
			<br>
			<small class="text-danger" *ngIf="f.numord.errors?.cannotContainSpace">Il numero ordine non può contenere spazi</small>
			<small class="text-danger" *ngIf="f.numord.errors?.notsevenchars">Il numero ordine deve essere di 7 caratteri</small>
			<br>
			<button type="submit" class="btn btn-primary" [disabled]="articoli.length || itemsForm.invalid">Seleziona</button>
		</div>
		<br>
		<div *ngIf="!articoli.length">
			<small class="alert alert-danger">Nessun articolo selezionato</small>
		</div>
		<div *ngFor="let a of articoli; let i = index" class="alert alert-success">
			Articolo : {{a.PN}} - Descrizione : {{a.DESCRIZIONE}}
			<br>
			Quantità : {{a.QTY}} - N. ordine : {{a.NUMEROORDINE}}
			<br>
			<button class="btn btn-danger" (click)="removeArticolo(i)">Rimuovi</button>
		</div>
	</form>
	</div>
</div>
</div>