import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

import { UserService } from '../../../_services/user.service'
import { ApiService } from '../../../_services/api.service'

@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.scss']
})
export class RejectedComponent implements OnInit {

  username: string;
  rejected: [] = [];

  constructor(private location: Location, private user: UserService, private api: ApiService) { }

  ngOnInit(): void {

    this.user.getUserInfo()
    .subscribe((res: any) => {
      this.api.getSegnalazioniRifiutate(res.completeDescription)
        .subscribe((res: any) => {
          this.rejected = res.data;
        })
    })

  }

  backClicked() {
    this.location.back();
  }

}
