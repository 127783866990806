import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-send-back',
  templateUrl: './send-back.component.html',
  styleUrls: ['./send-back.component.scss']
})
export class SendBackComponent implements OnInit {

  dataload : boolean = false;
  resi : any = [];
  resiSelected : any = [];
  today : any;

  constructor(private user : UserService) { }

  ngOnInit(): void {
    this.today = new Date().getTime();

    this.user.ResiObs.subscribe(res => {
      this.resi = res;
      this.dataload = true;
    })

  }

  selected(i : number) {
    if (this.resi[i][8] == true) {
      this.resi[i][8] = false;
    } else {
      this.resi[i][8] = true;
    }
  }

}
