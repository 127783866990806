<div class="container">
    <div class="row">
        <div class="col" style="text-align:center">
            <h3><b>ATTENZIONE</b></h3>
        </div>
    </div>

    <div class="row">
        <div class="col">
            Ci sono <b>{{articoli_totali}}</b> articoli da rendere
            <br>
            Di questi, <b>{{articoli_scaduti}}</b> articoli sono in attesa di reso da oltre 60 giorni
            <br><br>
            Nel caso in cui un articolo non venisse reso entro 120gg la segnalazione verrà automaticamente rifiutata
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col text-left">
            <div *ngIf="!openListaResi">
                <div class="button btn btn-primary" (click)="open()">
                    Visualizza
                </div>
            </div>
            <div *ngIf="openListaResi">
                <div class="button btn btn-primary" (click)="close()">
                    Nascondi
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col" *ngIf="openListaResi">
            <div class="row">
                <div class="col-3 text-center"><b>SEGNALAZIONE</b></div>
                <!-- <div class="col-2 text-center"><b>DATA</b></div> -->
                <div class="col-3 text-center"><b>ARTICOLO</b></div>
                <div class="col-1 text-center"><b>QUANTITA'</b></div>
                <div class="col-5 text-center"><b>DESCRIZIONE</b></div>
            </div>
            <hr>
            <div class="row" *ngFor="let r of resi">
                    <div [ngStyle]="{'color' : oldSGN(r[6]) ? 'red' : 'black', 'font-weight' : oldSGN(r[6]) ? 'bold' : 'normal'}" 
                        class="col-3 text-center">{{r[2]}}
                    </div>
                    <!-- <div class="col-2 text-center">{{r[6] | date: 'dd/MM/yyyy'}}</div> -->
                    <div class="col-3 text-center">{{r[3]}}</div>
                    <div class="col-1 text-center">{{r[5]}}</div>
                    <div class="col-5 text-center">{{r[4]}}</div>
                    <br>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top:15px">
        <div class="col" style="text-align:right">
            <div class="button btn btn-warning" (click)="closeDialog()">Chiudi</div>
        </div>
    </div>



</div>