import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SearchService } from '../../../_services/search.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-listcase',
  templateUrl: './listcase.component.html',
  styleUrls: ['./listcase.component.scss']
})
export class ListCaseComponent implements OnInit {

  case = [];
  dataload : boolean = false;
  Vettore : boolean = false;

  constructor(private search: SearchService, private location: Location, private user : UserService) { }

  ngOnInit(): void { 
    this.Vettore = this.user.Vettore;
    this.case = this.search.case;
    this.dataload = true;
  }

  backClicked() {
    this.location.back();
  }

}
