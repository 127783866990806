<!-- <app-header></app-header> -->
<app-user></app-user>
<app-navbar *ngIf="dataload" [Vettore]="Vettore"></app-navbar>

<!-- Se utente NON Vettore -->
<div *ngIf="!Vettore && dataload" class="container">
	<div class="row">
		<div class="col-sm-12 col-md-4">
			<div class="ricerca-articolo">
				<div class="titolo-ricerca">Ricerca per articolo</div>
				<div class="input-group">
					<input type="text" class="form-control" placeholder="Inserisci codice articolo" aria-label="Recipient's username" aria-describedby="Ricerca" #art [(ngModel)]="articolo">
					<div class="input-group-append">
						<button class="btn btn-info" type="button" id="button-addon2" (click)="searchSGNbyItems(art.value)" [disabled]="!articolo || articolo.length < 3">Cerca</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 col-md-4">
			<div class="ricerca-segnalazione">
				<div class="titolo-ricerca">Ricerca per numero</div>
				<div class="input-group">
					<input type="text" class="form-control" placeholder="Inserisci n. (es. 123)" aria-label="Recipient's username" aria-describedby="Ricerca" #number [(ngModel)]="numero">
					<div class="input-group-append">
						<button class="btn btn-info" type="button" id="button-addon3" (click)="searchSGNbyNumber(number.value)" [disabled]="!numero">Cerca</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 col-md-4">
			<div class="ricerca-segnalazione">
				<div class="titolo-ricerca">Ricerca per DDT</div>
				<div class="input-group">
					<input type="text" class="form-control" placeholder="Inserisci numero DDT" aria-label="Recipient's username" aria-describedby="Ricerca" #DDTnumber [(ngModel)]="DDTnumero">
					<div class="input-group-append">
						<button class="btn btn-info" type="button" id="button-addon4" (click)="searchSGNbyDDTNumber(DDTnumber.value)" [disabled]="!DDTnumero || DDTnumero.length < 2">Cerca</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Se utente NON Vettore -->
<div *ngIf="!Vettore && dataload" class="container">
    <div class="row align-items-center">
		<!-- ORIGINALE <div class="col-sm-12 col-md-4" (click)="searchPending()"> -->
        <div class="col-sm-12 col-md-3">
			<div class="box-attesa menu_links" (click)="searchPending()">
				<!-- ORIGINALE <a [routerLink]="['/case']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> In attesa</a> -->
				<div class="icone-box"><img class="ico-box" src="/assets/img/ico-inattesa.svg" alt="Segnalazioni IN ATTESA"></div>
				<div class="titolo-box">
					<div class="txt-pratiche">SEGNALAZIONI</div>
					<div class="txt-attesa">APERTE</div>
				</div>
			</div>
		</div>
		<!-- ORIGINALE <div class="col-sm-12 col-md-4" (click)="searchApproved()"> -->
        <div class="col-sm-12 col-md-3">
			<div class="box-accettate menu_links" (click)="searchApproved()">
				<!-- ORIGINALE <a [routerLink]="['/case']" routerLinkActive="active"> Accettate </a> -->
				<div class="icone-box"><img class="ico-box" src="/assets/img/ico-accettate.svg" alt="Segnalazioni ACCETTATE"></div>
				<div class="titolo-box">
					<div class="txt-pratiche">SEGNALAZIONI</div>
					<div class="txt-attesa">APPROVATE</div>
				</div>
			</div>
        </div>
		<!-- ORIGINALE <div class="col-sm-12 col-md-4" (click)="searchRejected()"> -->
        <div class="col-sm-12 col-md-3">
			<div class="box-rifiutate menu_links" (click)="searchRejected()">
				<!-- ORIGINALE <a [routerLink]="['/case']" routerLinkActive="active"> Rifiutate </a> -->
				<div class="icone-box"><img class="ico-box" src="/assets/img/ico-rifiutate.svg" alt="Segnalazioni RIFIUTATE"></div>
				<div class="titolo-box">
					<div class="txt-pratiche">SEGNALAZIONI</div>
					<div class="txt-attesa">RIFIUTATE</div>
				</div>
			</div>
        </div>
		<div class="col-sm-12 col-md-3">
			<div class="box-accreditate menu_links" (click)="searchPaid()">
				<div class="icone-box"><img class="ico-box" src="/assets/img/ico-accreditate.svg" alt="Segnalazioni ACCREDITATE"></div>
				<div class="titolo-box">
					<div class="txt-pratiche">SEGNALAZIONI</div>
					<div class="txt-accreditate">ACCREDITATE</div>
				</div>
			</div>
        </div>
    </div>
</div>


<!-- Se utente Vettore -->
<div *ngIf="Vettore && dataload" class="container">
	<br>
    <div class="row align-items-center">
		<!-- ORIGINALE <div class="col-sm-12 col-md-4" (click)="searchPending()"> -->
        <div class="col-sm-12 col-md-4">
			<div class="box-rifiutate menu_links" (click)="searchVerificaVettore()">
				<span class="badge badge-success"><span class="contatore">{{SGNcarrier}}</span></span>
				<!-- ORIGINALE <a [routerLink]="['/case']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> In attesa</a> -->
				<div class="icone-box">
					<img class="ico-box" src="/assets/img/ico-inattesa.svg" alt="Pratiche IN VERIFICA VETTORE">
				</div>
				<div class="titolo-box">
				<div class="txt-pratiche">VERIFICA</div>
				<div class="txt-attesa">VETTORE</div>
			</div>
		</div>
	</div>
</div>
