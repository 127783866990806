<div class="row" *ngIf="loaded">
	<div class="col-12">
    <h2 *ngIf="esito == 'rifiuta'" mat-dialog-title>Rifiuta segnalazione</h2>
    <h2 *ngIf="esito == 'approva'" mat-dialog-title>Approva segnalazione</h2>
		<form *ngIf="esito == 'rifiuta'" class="main" id="esitoVettoreRifiutaForm" [formGroup]="esitoVettoreRifiutaForm" (ngSubmit)="onSubmit()">
				  <mat-dialog-content class="mat-typography">
            <h3>Motivo del rifiuto</h3><input type="text" class="form-control" formControlName="motivo" id="motivo" #motivo autocomplete=off><br>           
          </mat-dialog-content>
          <mat-dialog-actions align="center">
          <div class="bottoni-invio">
            <button type="submit" class="btn btn-primary mr-4" form="esitoVettoreRifiutaForm" [disabled]="esitoVettoreRifiutaForm.invalid">Invia</button>
            <button mat-dialog-close class="btn btn-primary">Chiudi</button>
          </div>
        </mat-dialog-actions>
    </form>
    <form *ngIf="esito == 'approva'" class="main" id="esitoVettoreApprovaForm" [formGroup]="esitoVettoreApprovaForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content class="mat-typography">
      <h3>Rimborso da contratto</h3><input type="numeric" class="form-control" formControlName="rimborsocontratto" id="rimborsocontratto" #rimborsocontratto><br>           
      <h3>Rimborso</h3><input type="numeric" class="form-control" formControlName="rimborso" id="rimborso" #rimborso autocomplete=off><br>           
      <h3>N. spedizione</h3><input type="numeric" class="form-control" formControlName="spedizione" id="spedizione" #spedizione autocomplete=off><br>           
      <h3>Commento</h3><input type="text" class="form-control" formControlName="commento" id="commento" #commento autocomplete=off><br>           
      </mat-dialog-content>
      <mat-dialog-actions align="center">
      <div class="bottoni-invio">
        <button type="submit" class="btn btn-primary mr-4" form="esitoVettoreApprovaForm" [disabled]="esitoVettoreApprovaForm.invalid">Invia</button>
        <button mat-dialog-close class="btn btn-primary">Chiudi</button>
      </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>