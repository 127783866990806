import { Routes } from '@angular/router';
import { AuthGuard } from '../_helpers/auth.guard';
import { DashboardGuard } from '../_helpers/dashboard.guard';

import { LoginComponent } from '../containers/login/login.component';
import { LayoutComponent } from '../containers/layout/layout.component';
import { DashboardComponent } from '../containers/dashboard/dashboard.component';
import { NewCaseComponent } from '../containers/newcase/newcase.component';
import { PendingComponent } from '../containers/dashboard/pending/pending.component';
import { ApprovedComponent } from '../containers/dashboard/approved/approved.component';
import { RejectedComponent } from '../containers/dashboard/rejected/rejected.component';
import { DamagedComponent } from '../containers/newcase/forms/damaged/damaged.component';
import { DefectiveComponent } from '../containers/newcase/forms/defective/defective.component';
import { WrongitemComponent } from '../containers/newcase/forms/wrongitem/wrongitem.component';
import { ListCaseComponent } from '../containers/dashboard/listcase/listcase.component';
import { SendBackComponent } from '../containers/send-back/send-back.component';
import { PendingChangesGuard } from './pending-changes.guard';


export const AppRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'app', component: LayoutComponent, 
  children: [
    { path: '', component: DashboardComponent, canActivate: [DashboardGuard] },
    { path: 'newcase', component: NewCaseComponent, canActivate: [DashboardGuard] },
    { path: 'pending', component: PendingComponent, canActivate: [DashboardGuard] },
    { path: 'approved', component: ApprovedComponent, canActivate: [DashboardGuard] },
    { path: 'rejected', component: RejectedComponent, canActivate: [DashboardGuard] },
    { path: 'damaged', component: DamagedComponent, canActivate: [DashboardGuard], canDeactivate: [PendingChangesGuard] },
    { path: 'defective', component: DefectiveComponent, canActivate: [DashboardGuard], canDeactivate: [PendingChangesGuard] },
    { path: 'wrongitem', component: WrongitemComponent, canActivate: [DashboardGuard], canDeactivate: [PendingChangesGuard] },
    { path: 'listcase', component: ListCaseComponent, canActivate: [DashboardGuard] },
    { path: 'sendback', component: SendBackComponent, canActivate: [DashboardGuard] }
  ],
  canActivate: [DashboardGuard] }
]
