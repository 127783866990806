import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  case = [];

  constructor() {  }  

}
