<div class="container">
    <div class="row">
        <div class="col">

            <form id="boxForm" [formGroup]="boxForm" (ngSubmit)="onFormSubmit()">
                <div class="container">

                    <div class="row">
						<div class="col-12">
							<label>Peso</label><br />
							<input maxlength="10" size="10" type="text" class="form-control" formControlName="peso" name="peso" #peso>			
						</div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label>Altezza * Lunghezza * Profondità</label><br />
                            <input type="text" maxlength="10" size="10" class="form-control" formControlName="altezza" name="altezza" #altezza placeholder="Altezza">
                            <input type="text" maxlength="10" size="10" class="form-control" formControlName="lunghezza" name="lunghezza" #lunghezza placeholder="Lunghezza">
                            <input type="text" maxlength="10" size="10" class="form-control" formControlName="profondità" name="profondità" #profondità placeholder="Profondità">
                        </div>
                    </div>

                </div>
            </form>

        </div>
    </div>
<hr>
    <div class="row">
        <div class="col">
            <div *ngFor="let b of box">
                {{b}}
            </div>
        </div>
    </div>



</div>

