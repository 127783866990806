<br><br>

<div *ngIf="!Vettore" class="container">
	<div class="row">
		<div class="accordion" id="accordionExample">
			<div class="card">
				<div class="card-header" id="headingOne">
					<div class="box-accordion">
						<div *ngIf="!click">
							<div class="text-right" (click)="clicked()"><img class="freccia-giu menu_links" src="/assets/img/freccia-giu.png"></div>
						</div>
						<div *ngIf="click">
							<div class="text-right" (click)="clicked()"><img class="freccia-su menu_links" src="/assets/img/freccia-su.png"></div>
						</div>
						<div class="titoli-accordion">
							Segnalazione: <b>{{case[1]}}</b> <br />
							Data di apertura: <b>{{case[3] | date: 'dd/MM/yyyy'}}</b><br />
							Tipo: <b>{{case[4]}}</b><br />
							Data DDT: <b>{{case[9] | date: 'dd/MM/yyyy'}}</b><br />
							Numero DDT: <b>{{case[10]}}</b> <br />
							<div *ngIf="case[11]">Data consegna: <b>{{case[11] | date: 'dd/MM/yyyy'}}</b> <br /></div>
							Stato: <b [ngClass]="{
							'aperta' : case[5] == 'Aperta',
							'approvata' : case[5] == 'Approvata',
							'rifiutata' : case[5] == 'Rifiutata',
							'accreditata' : case[5] == 'Accreditata'
						 }">{{case[5]}}</b>
						 <!-- <div class="text-right" *ngIf="case[5] == 'Aperta'">
							<a class="btn btn-danger" (click)="undoSGN(case[0], case[1])">Rimuovi segnalazione</a>
						 </div> -->
						</div>
						<div *ngIf="case[5] == 'Accreditata'">
							<hr>
							Numero nota di credito : <b>{{case[14]}}</b><br>
							Data nota di credito : <b>{{case[12] | date: 'dd/MM/yyyy'}}</b><br>
							Importo nota di credito : <b>{{case[13] | number: '1.2-2'}} €</b>
						</div>					
							
					</div>
				</div>
				
				<div *ngIf="click">
					<div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
						
						<!-- Inizio tabella -->
						
						<div class="table-responsive" *ngIf="articoli.length">
							<table class="table">
								<thead>
									<tr>
										<th scope="col">Codice</th>
										<th scope="col">Descrizione</th>
										<th scope="col">Quantità</th>
										<th scope="col">Num. ordine</th>
										<th scope="col" *ngIf="case[4]==='Articolo/Quantità errati'">Origine</th>
									</tr>
								</thead>
								<tbody *ngFor="let a of articoli">
									<tr>
										<th scope="row">{{a[2]}}</th>
										<td>{{a[3]}}</td>
										<td>{{a[4]}}</td>
										<td>{{a[5]}}</td>
										<td *ngIf="case[4]==='Articolo/Quantità errati'">{{a[6]}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						
						<!-- Fine tabella -->
						<hr>
							<div class="col-sm-12 newline">{{case[6]}}</div>
							<div class="col-sm-12 newline" *ngIf="case[5] === 'Rifiutata'">
								<hr>
								Motivo del rifiuto : {{case[7]}}
								</div>
								<div class="col-sm-12 newline" *ngIf="case[8]">
									<hr>
									{{case[8]}}
								</div>
							<div *ngIf="click">
								<app-attachments [sidSGN]="case[0]"></app-attachments>
							</div>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="Vettore" class="container">
	<div class="row">
		<div class="accordion" id="accordionExample">
			<div class="card">
				<div class="card-header" id="headingOne">
					<div class="box-accordion">
						<div *ngIf="!click">
							<div class="text-right" (click)="clicked()"><img class="freccia-giu menu_links" src="/assets/img/freccia-giu.png"></div>
						</div>
						<div *ngIf="click">
							<div class="text-right" (click)="clicked()"><img class="freccia-su menu_links" src="/assets/img/freccia-su.png"></div>
						</div>
						<div class="titoli-accordion">
							Segnalazione: <b>{{case[3]}}</b> <br />
							Cliente: <b>{{case[1]}}</b> <br />
							Data DDT: <b>{{case[4] | date: 'dd/MM/yyyy'}}</b><br />
							Numero DDT: <b>{{case[5]}}</b> <br />
							<div *ngIf="case[8]">Segnacollo: <b>{{case[6]}}</b> <br /></div>
							Data consegna: <b>{{case[7] | date: 'dd/MM/yyyy'}}</b> <br />
						</div>			
						<div class="text-right">
							<a class="btn btn-danger" (click)="esitoVettore('rifiuta' , case[0])">Rifiuta</a> &nbsp;
							<a class="btn btn-success" (click)="esitoVettore('approva' , case[0], case[8])">Approva</a>
						</div>			
					</div>
				</div>
				
				<div *ngIf="click">
						<app-attachments [sidSGN]="case[0]"></app-attachments>
				</div>
			</div>
		</div>
	</div>
</div>

<br><br><hr>
