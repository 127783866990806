import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  u: string;
  codice : string;
  loaded:boolean = false;

  constructor(private user : UserService) { }

  ngOnInit(): void { 

    this.user.clienteObs.subscribe(res => {
      this.u = res.cliente;
      this.loaded = true;
    })

  }

}
