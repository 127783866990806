import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { ApiService } from 'src/app/_services/api.service';
import { SearchService } from 'src/app/_services/search.service';
import { ItemsComponent } from '../newcase/items/items.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  articolo: string = '';
  numero: string = '';
  DDTnumero: string = '';
  uid: string;
  dataload : boolean = false;
  groupVettore = [];
  Vettore: boolean = false;
  SGNcarrier : number = 0;
 
  constructor(private user: UserService, private api: ApiService, private search: SearchService, private router: Router) { }


  ngOnInit(): void {  
    this.user.getUserInfo()
    .subscribe((res: any) => {
      this.uid = res.user
      this.user.getVettoreGroup()
      .subscribe((group: any) => {
        this.groupVettore = group;
        this.groupVettore.forEach(el => {
          if (el.user === this.uid) {
            this.user.Vettore = true;
            this.Vettore = true;
          }
        })
        if (this.Vettore) {
          this.api.getSegnalazioniVerificaVettore()
            .subscribe((res:any) => {
              this.SGNcarrier = res.data.length
              this.dataload = true;
            })
        } else {
          this.dataload = true;
        }
        });
    });
  }


  searchSGNbyItems(art: string) {
    let array = [];
    this.search.case = [];

    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSIDSegnalazioniDaArticolo(res.completeDescription, art)
          .subscribe((res: any) => {
            if (res.data.length) {
              res.data.forEach((el,i) => {
                array[i] = el[1];
              })
              array = [...new Set(array)];
              array.forEach((el) => {
                 this.api.getSegnalazioneDaSID(el)
                  .subscribe((res: any) => {
                    this.search.case.push(res.data[0]);
                  })
              })
              this.router.navigate(['/app/listcase']);
            } else {
              this.router.navigate(['/app/listcase']);
            }    
          })
      })
  }

  searchSGNbyNumber(numero: string) {
    let array = [];
    this.search.case = [];
    numero = 'SGN/'+ numero

    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioneByNumber(res.completeDescription, numero)
          .subscribe((res: any) => {
              this.search.case = res.data;
              this.router.navigate(['/app/listcase']);
            })
      })
  }

  
  searchPending() {
    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioniAperte(res.completeDescription)
          .subscribe((res: any) => {
            this.search.case = res.data;
            this.router.navigate(['/app/listcase']);
          })
      })    
  }

  searchApproved() {
    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioniApprovate(res.completeDescription)
          .subscribe((res: any) => {
            this.search.case = res.data;
            this.router.navigate(['/app/listcase']);
          })
      })
  }

  searchRejected() {
    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioniRifiutate(res.completeDescription)
          .subscribe((res: any) => {
            this.search.case = res.data;
            this.router.navigate(['/app/listcase']);
          })
      })    
  }

  searchPaid() {
    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioniAccreditate(res.completeDescription)
          .subscribe((res: any) => {
            this.search.case = res.data;
            this.router.navigate(['/app/listcase']);
          })
      })    
  }

    searchSGNbyDDTNumber(numeroDDT: string) {
    let array = [];
    this.search.case = [];

    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioneByDDTNumber(res.completeDescription, numeroDDT)
          .subscribe((res: any) => {
              this.search.case = res.data;
              this.router.navigate(['/app/listcase']);
            })
      })
  }

  searchVerificaVettore() {
        this.api.getSegnalazioniVerificaVettore()
          .subscribe((res: any) => {
            this.search.case = res.data;
            this.router.navigate(['/app/listcase']);
          })
  }

}