import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ApiService } from '../../../../_services/api.service';
import { CsvService } from '../../../../_services/csv.service';
import { UserService } from '../../../../_services/user.service';

import { Segnalazione, ProfiloCreato, Profilo } from '../../../../_models/app.model';
import { ItemsComponent } from '../../items/items.component';
import { FileUploadService } from '../../../../_services/fileupload.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogSearchCustomerComponent, DialogSpecificReserveComponent, DialogOrderNumberComponent } from '../../../../_services/dialog.service';
import { DateValidator,NoSpaceValidator,FieldValidator, DateDDTValidator, DateConsegnaValidator } from '../../../../_helpers/forms.validator';
import { ToastService } from '../../../../_services/toast.service';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-damaged',
  templateUrl: './damaged.component.html',
  styleUrls: ['./damaged.component.scss']
})
export class DamagedComponent implements OnInit, OnDestroy {

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.canChangePage;
  }


  canChangePage : boolean = false;
  sgn: Segnalazione;
  numeroSGN: string = '';
  sidSGN: number;
  art: string;
  dataload:boolean = false;
  cli: string = '';
  codicecliente: string = '';
  uid: string;
  groupB2B = [];
  customerB2B: boolean = false;

  added: boolean = false;

  itemsArray: any[];
  photosArray: string[];
  photos2Array: string[];
  photos3Array: string[];

 
  constructor(private router : Router, private user: UserService, private fb: FormBuilder, private api: ApiService, private csv: CsvService, private location: Location, private items: ItemsComponent, private fileupload: FileUploadService, private dialog: MatDialog, private toast: ToastService) {
  }

  ngOnInit(): void {

    this.csv.articoli.length = 0;
    this.fileupload.files.length = 0;
    this.fileupload.files2.length = 0;
    this.fileupload.files3.length = 0;
    this.user.clienteB2BObs.subscribe((cliente:any) => {
      this.cli = cliente.cliente;
      this.codicecliente = cliente.codice;
      this.damagedForm.controls.cliente.setValue(this.cli);
    })
    this.itemsArray = this.csv.articoli;
    this.photosArray = this.fileupload.files;
    this.photos2Array = this.fileupload.files2;
    this.photos3Array = this.fileupload.files3;
    this.user.getUserInfo()
    .subscribe((res: any) => {
      this.uid = res.user
      this.user.getB2BGroup()
      .subscribe((group: any) => {
        this.groupB2B = group;
        this.groupB2B.forEach((el:any) => {
          if (el.user === this.uid) {
            this.customerB2B = true;
          }
        });
        if (this.customerB2B) {
          this.cli = res.completeDescription;
          this.codicecliente = res.description;
          this.damagedForm.controls.cliente.setValue(this.cli);
        }       
        this.dataload = true;
      })
    })
  }

  
  ngOnDestroy(): void {
    this.itemsArray.length = 0;
    this.photosArray.length = 0;
    this.photos2Array.length = 0;
    this.photos3Array.length = 0;
    this.user.pushCliente('','');
    this.user.pushClienteB2B('','');
    
  }

  backClicked() {
    this.location.back();
  }

  damagedForm = this.fb.group({
    'cliente': new FormControl({value : '', disabled : true}, [Validators.required]),
    // FieldValidator.sixchars -- DA AGGIUNGERE SE IL DDT DEVE PER FORZA ESSERE DA 6 CARATTERI
    'numeroDDT': new FormControl('', [Validators.required, NoSpaceValidator.cannotContainSpace, Validators.maxLength(6), Validators.pattern(/^[0-9]*$/)]),
    'dataDDT': new FormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]),
    'dataConsegna': new FormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]),
    'note': new FormControl(''),
    'riserva': new FormControl('', [Validators.requiredTrue])
  }, {validator: [DateValidator, DateDDTValidator, DateConsegnaValidator]});




  get f(){
    return this.damagedForm.controls;
  }


  onFormSubmit(): void {

    var buttonName = document.activeElement.getAttribute("id");

    if (buttonName === 'submit') {

    this.sgn = {NumeroDDT: this.damagedForm.get('numeroDDT').value, DataDDT: this.damagedForm.get('dataDDT').value, DataConsegna: this.damagedForm.get('dataConsegna').value, Tipologia: 'Danno da trasporto', Note: this.damagedForm.get('note').value}

    this.photosArray = this.photosArray.concat(this.photos2Array, this.photos3Array);

    this.api.addCase(this.sgn, this.cli, this.codicecliente, this.photosArray)
      .subscribe((res: ProfiloCreato) => {
        this.numeroSGN = res.internalProtocolNumber;
        this.sidSGN = res.docNumber;
        let index: number = 0;
        for (let id of this.csv.articoli) {
          this.api.addItems(this.cli, this.numeroSGN, this.sidSGN, index, id.ORIGINE)
            .subscribe((res: ProfiloCreato) => {
                index++;
            })
        }
          this.api.caricaProfile(this.sidSGN)
            .subscribe((res: Profilo) => {
              for (let el of res.fields) {
                if (el.name === 'NUMERIC15_2') {  
                  el.value = this.csv.articoli.length.toString();
                }
              }
              this.api.aggiornaProfile(this.sidSGN, res)
                .subscribe((res: any) => {
                    //this.fileupload.FileUpload(this.sidSGN)
                    this.itemsArray.length = 0;
                    this.added = true;
                    this.damagedForm.reset();
                    this.photosArray.length = 0;
                    this.photos2Array.length = 0;
                    this.photos3Array.length = 0;
                    if (this.customerB2B) {
                      this.damagedForm.controls.cliente.setValue(this.cli);
                    }
                    //window.scroll(0,0);
                    this.canChangePage = true;
                    this.router.navigate(['/app/newcase']);
                    this.toast.showSuccess('Segnalazione creata (' + this.numeroSGN + ')')
                })
            })
      })
    }
    if (buttonName === 'search') {
        this.openSearch();
    }
  }


openDialog(tipo: string) {
   if (tipo === 'DDT') {
    const dialogRef = this.dialog.open(DialogOrderNumberComponent);
   }
   if (tipo === 'RiservaSpecifica') {
    const dialogRef = this.dialog.open(DialogSpecificReserveComponent);
   }

}

openSearch() {
  const dialogRef = this.dialog.open(DialogSearchCustomerComponent,{
      height: '500px',
      width: '800px',
    });
}



}