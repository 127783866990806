import { Component, OnInit, Input } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage  } from 'ngx-gallery-9';
import { ApiService } from 'src/app/_services/api.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  SGN: string = '';
  urlSGN: string = '';
  urlID: string = '';
  urlEXT: string = '';
  nomeFile: string = '';


  @Input()
  sidSGN: string;
  
  allegati : boolean = false;
  dataload : boolean = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {

    this.galleryOptions =     
      [
      { "image": false, "thumbnailsRemainingCount": true, "height": "100px"},
      { "breakpoint": 500, "width": "100%", "thumbnailsColumns": 6 }
      ];

    this.api.getSegnalazioneDaSID(+this.sidSGN)
      .subscribe((res : any) => {
        this.SGN = res.data[0][1];
        this.urlSGN = this.SGN.replace('/', '');
        this.api.getAllegati(+this.sidSGN)
        .subscribe((res : any) => {
          if (res.length) {
            this.allegati = true;
          for (let el of res) {
            this.urlID = el.id;
            this.nomeFile = el.originalname;
            this.urlEXT = this.nomeFile.slice(this.nomeFile.lastIndexOf('.') + 1);
            this.galleryImages.push(
              {
                small: 'https://api-spares-it.electrolux.com:8080/attachments/' + this.urlSGN + '/' + this.urlID + '.' + this.urlEXT,
                big: 'https://api-spares-it.electrolux.com:8080/attachments/' + this.urlSGN + '/' + this.urlID + '.' + this.urlEXT
              }
            )
          }
          }
          this.dataload = true;
        })       
      })
    }
}



