<!-- <app-header></app-header> -->

<app-navbar></app-navbar>
<div class="container">
    <div class="row">
        <div *ngFor="let c of cs" class="col-sm-12 col-md-4">
			<div class="newcase-box-scelte">
				<a class="newcase-scelte" routerLinkActive="active" (click)="nav(c.url)">{{c.causale}}</a>
			</div>
		</div>
    </div>
</div>