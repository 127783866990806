import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

import { UserService } from '../../../_services/user.service'
import { ApiService } from '../../../_services/api.service'

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent implements OnInit {

  username: string;
  pending: [] = [];

  constructor(private location: Location, private user: UserService, private api: ApiService) { }

  ngOnInit(): void {
  
    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioniAperte(res.completeDescription)
          .subscribe((res: any) => {
            this.pending = res.data;
          })
      })
  
  }

  backClicked() {
    this.location.back();
  }


  

}
