import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, of, pipe } from 'rxjs'
import { mapTo, tap, catchError } from 'rxjs/operators'

import { config } from '../_helpers/config'
import {Tokens} from '../_models/tokens'
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

    private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    private loggedUser: string;
  
    constructor(private http: HttpClient, private user: UserService, private router : Router, private toast : ToastService) {}
  
    login(user: { username: string, password: string, clientId: string, clientSecret: string}): Observable<boolean> {
      return this.http.post<any>(`${config.apiUrl}/api/Authentication`, user)
        .pipe(
          tap(
            tokens => {
              this.doLoginUser(user.username, tokens)
            },
          ),
          mapTo(true),
          catchError(error => {
            this.toast.showError(error.error.userMessage);
            return of(false);
          }));
    }
  
    logout() {
      this.loggedUser = null;
      this.removeTokens();
    }
  
    isLoggedIn() {
      return !!this.getJwtToken();
    }
  
    refreshToken() {
      return this.http.post<any>(`${config.apiUrl}/api/Authentication/refreshtoken`, {
        'refreshToken': this.getRefreshToken()
      }).pipe(tap((tokens: Tokens) => {
        this.storeJwtToken(tokens.accessToken);
      }));
    }
  
    getJwtToken() {
      return localStorage.getItem(this.ACCESS_TOKEN);
    }
  
    private doLoginUser(username: string, tokens: Tokens) {
      this.loggedUser = username;
      this.storeTokens(tokens);
    }
  
    // private doLogoutUser() {
    //   this.loggedUser = null;
    //   this.removeTokens();
    // }
  
    private getRefreshToken() {
      return localStorage.getItem(this.REFRESH_TOKEN);
    }
  
    private storeJwtToken(jwt: string) {
      localStorage.setItem(this.ACCESS_TOKEN, jwt);
    }
  
    private storeTokens(tokens: Tokens) {
      localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }
  
    private removeTokens() {
      localStorage.removeItem(this.ACCESS_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
      localStorage.removeItem('USERNAME');
    }

    autoLogout(){
      this.http.get<any>(`${config.apiUrl}/api/Authentication/identityInfo`)
        .subscribe(user => {
          let token = new Date(user.expiresUtc).getTime();
          let now = new Date().getTime();
          let diff = token - now;
          setTimeout(() => {
            this.logout();
            this.user.Vettore = false;
            this.router.navigate(['/login']);
          }, diff)
      }, (error => {
        console.log(error);
        this.logout();
        this.user.Vettore = false;
        this.router.navigate(['/login']);
      })
      )
    }
  }