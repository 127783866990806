<!-- <app-header></app-header> -->
<app-navbar></app-navbar>

<div class="container">
    <div class="row">
        <div class="col-12">
			<h2 class="titolo-nuovasegnalazione">
				<!-- <span class="titolo-light">Inserisci nuova segnalazione:</span> -->
				<!-- <br /> -->
				Non conforme
				<!-- <img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog()"> -->
			</h2>
		</div>
			
        <div class="col-sm-12">
        <form *ngIf="dataload" class="main" id="defectiveForm" [formGroup]="defectiveForm" (ngSubmit)="onFormSubmit()">
            <div class="container">
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<label>Cliente</label><br />
						<b><input class="form-control" formControlName="cliente" name="cliente" #cliente></b>
						<button id="search" class="btn btn-warning" *ngIf="!customerB2B">Cerca</button>						
					</div>
				</div>
                <div class="row">
                    <div class="col-sm-12 col-md-3">
						<label>Numero DDT<sup class="red"> *</sup></label><sup><img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog('DDT')"></sup><br />
						<input class="form-control" formControlName="numeroDDT" #numeroDDT maxlength="6">
						<small class="text-danger" *ngIf="f.numeroDDT.errors?.cannotContainSpace">Il numero DDT non può contenere spazi</small>
						<small class="text-danger" *ngIf="f.numeroDDT.errors?.notsixchars">Il numero DDT deve essere di 6 caratteri</small>
                    </div>
                    <div class="col-sm-12 col-md-3">
						<label>Data DDT<sup class="red"> *</sup></label><br />
						<input class="form-control" type="date" formControlName="dataDDT">
						<small class="text-danger" *ngIf="defectiveForm.errors?.DateDDTBeforeToday">Data DDT non corretta</small>
                    </div>
                    <!-- <div class="col-sm-12 col-md-3">
						<label>Data consegna</label><br />
						<input class="form-control" type="date" formControlName="dataConsegna">
						<small class="text-danger" *ngIf="defectiveForm.errors?.misMatch">La data di consegna non può essere precedente alla data DDT</small>
                    </div> -->
                </div>
                
				<hr class="form-separatore">
				
                <div class="row">
                    <div class="col-sm-12 col-md-3">
						<label></label><br />
                        <mat-form-field appearance="fill">
                            <mat-label>Tipologia<sup class="red"> *</sup></mat-label>
                            <mat-select formControlName="type" (selectionChange)="selectInput($event)">
                              <mat-option value="Difetto funzionale">Difetto funzionale</mat-option>
                              <mat-option value="Non conforme">Non conforme</mat-option>
							  <mat-option value="Danneggiato">Danneggiato</mat-option>
                            </mat-select>
                          </mat-form-field>
						</div>
                    <div class="col-sm-12 col-md-4" *ngIf="isNonConforme">
						<label>PNC</label><br />
						<input class="form-control" id="PN" #PN formControlName="PN">
					</div>
                    <div class="col-sm-12 col-md-4" *ngIf="isNonConforme">
						<label>SN</label><br />
						<input class="form-control" id="SN" #SN formControlName="SN">
					</div>

                </div>
            
				<hr class="form-separatore">
				
				<div class="row">
					<div class="col-sm-12">
						<label>Descrizione difetto<sup class="red"> *</sup></label><br />
						<textarea class="form-control" formControlName="note"></textarea>
					</div>
				</div>
				
				<hr class="form-separatore">
				
				Articolo<sup class="red"> *</sup>
				<app-items></app-items>
				
				
				
				<div *ngIf="isNonConforme || isDanneggiato">
					<hr class="form-separatore">
					<label>Immagini<sup class="red"> *</sup></label>
					<app-photos></app-photos>
				</div>
				
				<!-- <hr class="form-separatore"> -->

				<hr>
				<sup class="red"> *</sup> Informazioni necessarie
			
			</div>
			
			<div class="bottoni-invio">
				<button type="submit" id="submit" class="btn btn-primary mr-4" form="defectiveForm" [disabled]="defectiveForm.invalid || !cli || !itemsArray.length || (isNonConforme && photosArray.length < 1) || (isDanneggiato && photosArray.length < 1)">Apri</button>
				<button type="reset" class="btn btn-primary" (click)="backClicked()">Indietro</button>
			</div>
			
			</form>

        <!-- <div *ngIf="numeroSGN && added">
            Segnalazione creata. Il numero della segnalazione è {{numeroSGN}}
        </div> -->
        <!-- <app-photos></app-photos>
        <br>
        <hr> -->
			
		</div><!-- /col MAIN FORM -->
	</div><!-- /row -->
</div><!-- /container -->