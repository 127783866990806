<div class="fondino">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-sm-12 col-md-4 logo-header">
				<a [routerLink]="['']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
					<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						 viewBox="0 0 261.6 60.1" style="enable-background:new 0 0 261.6 60.1;" xml:space="preserve">
					<style type="text/css">
						.st0{fill:#FFFFFF;}
					</style>
					<g id="logo_Electrolux" transform="translate(-101.421 -224.584)">
						<path id="Tracciato_1" class="st0" d="M101.4,284.6h60v-60.1h-60V284.6z M139.9,229.2c14,4.7,21.6,19.9,16.9,33.9
							c-2.7,8-8.9,14.3-16.9,16.9V229.2z M131.4,227.8c1.9,0,3.8,0.2,5.7,0.6c0,13.6-13.2,24.8-26.7,24.8l-5.7,0
							C105.6,239.3,117.2,227.8,131.4,227.8L131.4,227.8z M110.4,256c13.6,0,26.7,11.2,26.7,24.8c-1.9,0.4-3.8,0.6-5.7,0.6
							c-14.3,0-26.2-11.5-26.8-25.4L110.4,256z"/>
						<g id="Raggruppa_1" transform="translate(176.455 239.43)">
							<path id="Tracciato_2" class="st0" d="M5.1,20.5v-3.8h12.5v-4.8H5.1v-7h13.3V0H0v20.2c0,5.1,2.2,8.6,7.3,9.3c0.1,0,0.2,0,0.3,0
								h0.1c0.5,0.1,1.1,0.1,1.6,0.1h9.5v-4.8H9.3C7.7,24.9,5.1,24.1,5.1,20.5z"/>
							<path id="Tracciato_3" class="st0" d="M22.4,14v15.7h5.1V0h-5.1V14z"/>
							<path id="Tracciato_4" class="st0" d="M42.2,7.6c-6.5,0-11.3,4.5-11.3,11.4v0.2c0,6.8,4.7,11.1,11.4,11.1c3.4,0.1,6.8-1.3,9.1-3.9
								l-3.1-3c-1.5,1.7-3.6,2.6-5.8,2.5c-3.8,0-6.3-2.2-6.5-5.7h16.8c0.1-0.9,0.1-1.8,0.1-1.8C52.8,12.4,48.6,7.6,42.2,7.6z M36.1,16.6
								c0.2-2.6,2.9-4.7,6.1-4.7c3,0,5.4,2.1,5.4,4.7H36.1z"/>
							<path id="Tracciato_5" class="st0" d="M67.2,12.1c2.1,0,4.1,0.8,5.5,2.4l3.2-3.3c-4.3-4.7-11.5-5-16.2-0.7
								c-4.7,4.3-5,11.5-0.7,16.2s11.5,5,16.2,0.7c0.3-0.2,0.5-0.5,0.7-0.7l-3.2-3.3c-1.4,1.5-3.4,2.4-5.5,2.4c-3.7-0.1-6.7-3.2-6.6-6.9
								C60.7,15.1,63.6,12.2,67.2,12.1L67.2,12.1z"/>
							<path id="Tracciato_6" class="st0" d="M84.2,20.5L84.2,20.5v-7.6h4.7V8.2h-4.7V0h-5.1v20.8h0c0.1,4.8,2.3,8.1,7.3,8.8
								c0.1,0,0.2,0,0.3,0h0.1c0.5,0.1,1.1,0.1,1.6,0.1h0.5l0-4.8h-0.5C86.8,24.9,84.2,24.1,84.2,20.5z"/>
							<path id="Tracciato_7" class="st0" d="M98.2,11.4V8.2h-5.1v21.5h5.1V18.4c0-3.5,2.3-5.8,5.8-5.8c0.7,0,1.4,0.1,2.1,0.2V7.9
								c-0.5-0.1-1-0.1-1.5-0.1C101.5,7.8,99.2,9.2,98.2,11.4z"/>
							<path id="Tracciato_8" class="st0" d="M118.7,7.6c-6.3,0.2-11.2,5.4-11,11.7c0.2,6.3,5.4,11.2,11.7,11c6.2-0.2,11.1-5.2,11.1-11.4
								c0-6.3-5.2-11.4-11.5-11.4C118.8,7.6,118.7,7.6,118.7,7.6z M118.7,25.7c-3.7,0-6.8-2.9-6.8-6.7c0-3.7,2.9-6.8,6.7-6.8
								c3.7,0,6.8,2.9,6.8,6.7c0,0,0,0.1,0,0.1c0.1,3.6-2.8,6.6-6.4,6.7C118.8,25.7,118.7,25.7,118.7,25.7L118.7,25.7z"/>
							<path id="Tracciato_9" class="st0" d="M133.5,13.5v16.2h5.1V0h-5.1V13.5z"/>
							<path id="Tracciato_10" class="st0" d="M157.1,20.7c0,3.4-2,4.9-4.7,4.9s-4.7-1.5-4.7-4.8V8.2h-5.2v13c0,6.1,4.5,9.1,9.8,9.1
								c5.4,0,9.9-3,9.9-9.2V8.2h-5.2V20.7z"/>
							<path id="Tracciato_11" class="st0" d="M181.6,22.8l-3.2-4.5l4-5.4l3.4-4.7h-5.6l-4.7,6.4l-4.7-6.4h-5.5l3.2,4.3l4.2,5.8l-4.4,6.2
								l-3.7,5.2h5.5l5.5-7.6l5.5,7.6h5.5L181.6,22.8z"/>
						</g>
					</g>
					</svg>
				</a>
			</div>
			
			<div class="col-sm-12 col-md-8">
				<!-- <div class="menu">
					<a href="" class="float-right logout-btn" (click)="logout()">LOGOUT</a>
				</div> -->
				<div class="dropdown menu">
					<button class="btn dropdown-toggle float-right logout-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					  <!-- Dropdown button -->
					  <i class="material-icons">account_circle</i>
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
					  <a class="dropdown-item menu_links" (click)="getManuale()">MANUALE UTENTE</a>
					  <a class="dropdown-item menu_links" (click)="openDialog()">CAMBIO PASSWORD</a>
					  <a class="dropdown-item menu_links" (click)="logout()">ESCI</a>
					</div>
				  </div>
			</div>
		</div>
	</div>
</div>
<div class="img-header"></div>