import { Component, OnInit } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { config } from '../../_helpers/config';
import {MatDialog} from '@angular/material/dialog';

import {DialogArticoliDaRendereComponent, DialogChangePasswordComponent} from '../../_services/dialog.service'
import { ApiService } from '../../_services/api.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  ArrayResi = [];
  today : any = new Date();
  TotaleResi : number = 0;
  TotaleResiInScadenza : number = 0;

  constructor(private user: UserService, private auth: AuthService, private router: Router, private dialog: MatDialog, private api: ApiService, private http: HttpClient, private date : DatePipe) {
  }
    
  ngOnInit(): void {
    this.today = this.today.getTime();

    //console.log('carico header')
    this.auth.autoLogout();
    this.user.getUserInfo()
    .subscribe((res: any) => {
      this.user.pushCliente(res.description,res.completeDescription);

      this.api.GetArticoliDaRendere(res.description) //es. 50068259
        .subscribe((res:any) => {

          this.TotaleResi = res.data.length;
          //console.log(res.data);
          this.TotaleResiInScadenza = res.data.filter(rs => Date.parse(rs[6]) <= this.today).length


          this.user.pushResi(res.data)
          
          if (this.user.AlertResi && this.TotaleResiInScadenza) {
            this.dialog.open(DialogArticoliDaRendereComponent, {
              data : {articoli_totali : this.TotaleResi, articoli_scaduti : this.TotaleResiInScadenza}
            })
            this.user.AlertResi = false;
          }
        })
    })
  }

  logout() {
    this.auth.logout();
    this.user.Vettore = false;
    this.router.navigate(['/login']);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogChangePasswordComponent);
  
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  getManuale() {
    this.api.getSIDdaOggetto('Manuale WebAPP')
      .subscribe((res : any) => {
        let sid = res.data[0][0];
          this.http.get(`${config.apiUrl}/api/Documents/` + sid, { responseType: 'blob' }).subscribe(data => {
            const blobURL = URL.createObjectURL(data);
            window.open(blobURL, 'Manuale WebAPP');
          }, error => {
            console.log(error);
          });
      })
  }

}

