import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { UserService } from 'src/app/_services/user.service';
import { ApiService } from 'src/app/_services/api.service';

@Component({
  selector: 'app-approved',
  templateUrl: './approved.component.html',
  styleUrls: ['./approved.component.scss']
})
export class ApprovedComponent implements OnInit {

  username: string;
  approved: [] = [];

  constructor(private location: Location, private user: UserService, private api: ApiService) { }

  ngOnInit(): void {

    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSegnalazioniApprovate(res.completeDescription)
          .subscribe((res: any) => {
            this.approved = res.data;
          })
      })

  }

  backClicked() {
    this.location.back();
  }

}
