import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProfiloArticolo } from '../_models/app.model'

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor(private http: HttpClient) { }

  articoli : ProfiloArticolo[] = [];
  articoliord : ProfiloArticolo[] = [];
  articolirec : ProfiloArticolo[] = [];

  getArticolo(art: string) {
    return this.http.get(`https://api-spares-it.electrolux.com:8080/api/GetArticoloFromPN?PN=` + art) //GET Articolo by PN
  }

  getPrezzoVendita(art: string, codice: string, dataDDT: any, numeroDDT: string) {
    let mese: string = dataDDT.month();
    let anno: string = dataDDT.year();

    return this.http.get(`https://api-spares-it.electrolux.com:8080/csv/?source=https://api-spares-it.electrolux.com:8080/csv/sales/` + anno + `/` + mese + `.csv&PN=` + art + `&PN=` + art) //GET PrezzoVendita by PN
  }

}
