import { Component } from "@angular/core";
import { FileUploadService } from '../../../_services/fileupload.service'
import { NewFilePickerAdapter } from '../../../_helpers/file-picker.adapter';
import { UploaderCaptions } from "ngx-awesome-uploader";
import { HttpClient } from "@angular/common/http";
import { ToastService } from "../../../_services/toast.service";


@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent {

  adapter = new  NewFilePickerAdapter(this.http);
  files : any[]
  captions: UploaderCaptions = {
    dropzone: {
      title: "Trascina qui",
      or: "oppure",
      browse: "Sfoglia"
    },
    cropper: {
      crop: "",
      cancel: ""
    },
    previewCard: {
      remove: "",
      uploadError: ""
    }
  };

  constructor (private http: HttpClient, private fileupload: FileUploadService, private toast: ToastService) { }

  ngOnInit() {
    this.files = this.fileupload.files;
  }

  uploadSuccess(ev){
    ev.uploadResponse.forEach(res => {
      this.files.push(res);
    })
  }

  onRemoveSuccess(ev) {
    var value = ev.uploadResponse[0];
    this.files.splice(this.files.indexOf(value),1);
  }

  onValidationError(ev) {
    if (ev.error == "EXTENSIONS") {
      this.toast.showError('Estensione immagine non consentita. Le estensioni consentite sono : jpg, png')
    }
    if (ev.error == "FILE_MAX_SIZE") {
      this.toast.showError('La dimensione supera i 3 MB consentiti')
    }
  }

 

  

//   @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  

//   /**
//    * on file drop handler
//    */
//   onFileDropped($event) {
//     this.prepareFilesList($event);
//   }

//   /**
//    * handle file from browsing
//    */
//   fileBrowseHandler(files) {
//     this.prepareFilesList(files);
//   }

//   /**
//    * Delete file from files list
//    * @param index (File index)
//    */
//   deleteFile(index: number) {
//     if (this.files[index].progress < 100) {
//       //console.log("Upload in progress.");
//       return;
//     }
//     this.files.splice(index, 1);
//   }

//   /**
//    * Simulate the upload process
//    */
//   uploadFilesSimulator(index: number) {
//     setTimeout(() => {
//       if (index === this.files.length) {
//         return;
//       } else {
//         const progressInterval = setInterval(() => {
//           if (this.files[index].progress === 100) {
//             clearInterval(progressInterval);
//             this.uploadFilesSimulator(index + 1);
//           } else {
//             this.files[index].progress += 5;
//           }
//         }, 200);
//       }
//     }, 1000);
//   }

//   /**
//    * Convert Files list to normal array list
//    * @param files (Files List)
//    */
//   prepareFilesList(files: Array<any>) {
//     for (const item of files) {
//       item.progress = 0;
//       this.files.push(item);
//     }
//     this.fileDropEl.nativeElement.value = "";
//     this.uploadFilesSimulator(0);
//   }

//   /**
//    * format bytes
//    * @param bytes (File size in bytes)
//    * @param decimals (Decimals point)
//    */
//   formatBytes(bytes, decimals = 2) {
//     if (bytes === 0) {
//       return "0 Bytes";
//     }
//     const k = 1024;
//     const dm = decimals <= 0 ? 0 : decimals;
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
//   }

}
