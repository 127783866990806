<div *ngIf="!Vettore && dataload" class="container">
	<div class="row">
		<div class="col-12">
			<nav>
				<a class="btn btn-primary" [routerLink]="['/app']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Dashboard</a>
					&nbsp;&nbsp;&nbsp; 
				<a class="btn btn-primary" [routerLink]="['/app/newcase']" routerLinkActive="active">Nuova segnalazione</a>
					&nbsp;&nbsp;&nbsp;
				
				<!-- <a class="btn btn-primary" [routerLink]="['/app/sendback']" routerLinkActive="active">Gestione resi&nbsp;
					<span *ngIf="resi" class="badge badge-warning">{{resi}}</span>
					<span *ngIf="resi" class="sr-only">Resi</span>
				</a> -->
			</nav>
		</div>
	</div>
</div>

<div *ngIf="Vettore && dataload" class="container">
	<div class="row">
		<div class="col-12">
			<nav>
				<a class="btn btn-primary" [routerLink]="['']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Dashboard</a>	 
			</nav>
		</div>
	</div>
</div>