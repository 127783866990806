import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CausaliSegnalazione } from '../../_models/app.model';

@Component({
  selector: 'app-newcase',
  templateUrl: './newcase.component.html',
  styleUrls: ['./newcase.component.scss']
})
export class NewCaseComponent implements OnInit {

  cs: CausaliSegnalazione[] = [
    {causale:"Danno da trasporto", url:"damaged"},
    {causale:"Non conforme", url:"defective"},
    {causale:"Articolo/Quantità errati", url:"wrongitem"},
    ]

  constructor(private router: Router) {  }

  ngOnInit(): void {
  }

  nav(url: string) {
    this.router.navigate(['/app/' + url])

  }

}