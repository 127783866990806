import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-sb',
  templateUrl: './sb.component.html',
  styleUrls: ['./sb.component.scss']
})
export class SbComponent implements OnInit, OnDestroy {

  reso : any;
  articoli : any = [];

  constructor(private user : UserService, private api : ApiService) { }

  ngOnInit(): void {
    this.user.Reso
      .subscribe((res:any) => {
        this.reso = res;
        if (this.reso.length) {
          //se reso è selezionato
          this.api.getArticoliReso(this.reso[2])
            .subscribe((res:any) => {
              this.articoli = res.data;
              console.log(res.data);
            })
        }
      })  
  }

  organizza() {
    console.log('organizza');
  }

  reset(){
    this.user.Reso.next([]);
  }

  ngOnDestroy(): void {
    this.user.Reso.next([]);
  }

}
