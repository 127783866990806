import {AbstractControl , ValidationErrors} from '@angular/forms';

export function DateValidator(control: AbstractControl) : ValidationErrors | null {
    const dataDDT = control.get('dataDDT').value;
    const dataConsegna = control.get('dataConsegna').value;

    return dataDDT && dataConsegna && (dataDDT > dataConsegna) ?
        { misMatch : true } :
        null ;
}

export function DateDDTValidator(control: AbstractControl) : ValidationErrors | null {
  const dataDDT = control.get('dataDDT').value;
  const dataOggi = new Date().toISOString().slice(0, 10);

  return dataDDT && (dataDDT > dataOggi) ? 
  { DateDDTBeforeToday : true } : 
      null ;
}

export function DateConsegnaValidator(control: AbstractControl) : ValidationErrors | null {
  const dataConsegna = control.get('dataConsegna').value;
  const dataOggi = new Date().toISOString().slice(0, 10);

  return dataConsegna && (dataConsegna > dataOggi) ? 
  { DateConsegnaBeforeToday : true } : 
      null ;
}


export class NoSpaceValidator {
  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
      if ((control.value as string)) {
        if((control.value as string).indexOf(' ') >= 0){
          return {cannotContainSpace: true}
        }
      }

      return null;
  }
}

export class FieldValidator {
  static sixchars(control: AbstractControl) : ValidationErrors | null {
      if ((control.value as string)) {
        if((control.value as string).length != 6){
          return {notsixchars: true}
        }
      }

      return null;
  }

  static sevenchars(control: AbstractControl) : ValidationErrors | null {
    if ((control.value as string)) {
      if((control.value as string).length != 7){
        return {notsevenchars: true}
      }
    }

    return null;
}
}