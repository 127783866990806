
<app-navbar *ngIf="dataload" [Vettore]="Vettore"></app-navbar>

<div *ngIf="dataload" class="container">
    <div class="row">
        <div class="col-12">
            <div class="nessuna-segnalazione" *ngIf="!resi.length">
                Nessun articolo da rendere
            </div>

            <br>

            <div class="row" *ngIf="resi.length">
                <div class="center col-5">
                    <h3>Articoli da rendere</h3>
                </div>
                <div class="center col-7">
                    <h3>Gestione colli</h3>
                </div>
            </div>

            <div class="row">
                <div class="col-5">
                    <div *ngFor="let rs of resi; let i = index" class="list-group">
                        <a (click)="selected(i)" class="list-group-item list-group-item-action art" [ngClass]="{'selected': rs[8]}">
                            <b>Articolo : {{rs[3]}}</b><br>
                            Descrizione : {{rs[4]}}<br>
                            Quantità : {{rs[5]}}<br>
                            Rif. Segnalazione : {{rs[2]}}<br>
                            Scadenza reso : {{rs[7] | date: 'dd/MM/yyyy'}}
                        </a>
                    </div>
                </div>

                <div class="col-7">
                    <app-box></app-box>
                </div>
              </div>
        </div>
    </div>
</div>






