import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { SearchService } from '../../../_services/search.service';
import { UserService } from '../../../_services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  articolo: string = '';

  constructor(private api: ApiService, private search: SearchService, private user: UserService, private router: Router) { }

  ngOnInit(): void {
  }

  searchSGNbyItems(art: string) {
    let array = [];
    this.search.case = [];

    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSIDSegnalazioniDaArticolo(res.completeDescription, art)
          .subscribe((res: any) => {
            if (res.data.length) {
              console.log(res.data);
              let unique = [...new Set(res.data)];
              console.log(unique);
              res.data.forEach((el,i) => {
                 array[i] = el[1];
                 this.api.getSegnalazioneDaSID(array[i])
                  .subscribe((res: any) => {
                    this.search.case.push(res.data[0]);
                  })
              })
              this.router.navigate(['/listcase']);
            } else {
              this.router.navigate(['/listcase']);
            }    
          })
      })
  }

  searchSGNbyNumber(art: string) {
    let array = [];
    this.search.case = [];

    this.user.getUserInfo()
      .subscribe((res: any) => {
        this.api.getSIDSegnalazioniDaArticolo(res.completeDescription, art)
          .subscribe((res: any) => {
            if (res.data.length) {
              res.data.forEach((el,i) => {
                 array[i] = el[1];
                 this.api.getSegnalazioneDaSID(array[i])
                  .subscribe((res: any) => {
                    this.search.case.push(res.data[0]);
                  })
              })
              this.router.navigate(['/listcase']);
            } else {
              this.router.navigate(['/listcase']);
            }    
          })
      })
  }

}
