<!-- <app-header></app-header> -->
<app-navbar></app-navbar>
<br>
<div class="container">
    <div class="row">
		<div class="col-12">
			<h2 class="titolo-nuovasegnalazione">
				<!-- <span class="titolo-light">Inserisci nuova segnalazione:</span> -->
				<!-- <br /> -->
				Articolo/Quantità errati
				<!-- <img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog()"> -->
			</h2>
		</div>
		
        <div class="col-sm-12">
        <form *ngIf="dataload" class="main" id="wrongitemForm" [formGroup]="wrongitemForm" (ngSubmit)="onFormSubmit()">
            <div class="container">
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<label>Cliente</label><br />
						<b><input class="form-control" formControlName="cliente" name="cliente" #cliente></b>
						<button id="search" class="btn btn-warning" *ngIf="!customerB2B">Cerca</button>						
					</div>
				</div>
                <div class="row">
					<div class="col-sm-12 col-md-3">
						<label>Numero DDT<sup class="red"> *</sup></label><sup><img class="icona-informazioni" src="/assets/img/ico-puntodidomanda.svg" alt="Info" (click)="openDialog('DDT')"></sup><br />
						<input class="form-control" formControlName="numeroDDT" #numeroDDT maxlength="6">
						<small class="text-danger" *ngIf="f.numeroDDT.errors?.cannotContainSpace">Il numero DDT non può contenere spazi</small>
						<small class="text-danger" *ngIf="f.numeroDDT.errors?.notsixchars">Il numero DDT deve essere di 6 caratteri</small>
                    </div>
                    <div class="col-sm-12 col-md-3">
						<label>Data DDT<sup class="red"> *</sup></label><br />
						<input class="form-control" type="date" formControlName="dataDDT">
						<small class="text-danger" *ngIf="wrongitemForm.errors?.DateDDTBeforeToday">Data DDT non corretta</small>
                    </div>
				</div>
				
				<hr class="form-separatore">
				
				<div class="row">
					<div class="col-sm-12">
						<label>Descrizione difetto</label><br />
						<textarea class="form-control" formControlName="note"></textarea>
					</div>
				</div>
				
				<hr class="form-separatore">
							
				<div class="row">
					<div class="col-sm-12">
						Articolo/Quantità ordinati<sup class="red"> *</sup>
						<!--Richiamo <app-items2> per caricare l'articolo ordinato-->
						<app-ItemsOrdered></app-ItemsOrdered>
					</div>
				</div>
				
				<hr class="form-separatore">

				<div class="row">
					<div class="col-sm-12">
						Articolo/Quantità ricevuti<sup class="red"> *</sup>
						<!-- Richiamo <app-items> per caricare l'articolo ordinato -->
						<app-ItemsReceived></app-ItemsReceived>
					</div>
				</div>
				
				<hr class="form-separatore">

				<div class="row">
					<div class="col-12">
						<label class="form-check-label">Immagine etichetta di prelievo</label>
						<app-photos3></app-photos3>
					</div>
				</div>
				<br><br><br>

				<hr>
				<sup class="red"> *</sup> Informazioni necessarie
			
			</div>
			
			<div class="bottoni-invio">
				<button type="submit" id="submit" class="btn btn-primary mr-4" form="wrongitemForm" [disabled]="wrongitemForm.invalid || !cli || !itemsArrayOrd.length || !itemsArrayRec.length">Apri</button>
				<button type="reset" class="btn btn-primary" (click)="backClicked()">Indietro</button>
			</div>
			
			</form>
			
			<!-- <div *ngIf="numeroSGN && added">
			Segnalazione creata. Il numero della segnalazione è {{numeroSGN}}
			</div> -->
        </div><!-- /col MAIN FORM -->
	</div><!-- /row -->
</div><!-- /container -->