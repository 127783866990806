import { Injectable } from '@angular/core';
import { config } from '../_helpers/config'
import { HttpClient } from '@angular/common/http'
import {BehaviorSubject, Subject} from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class UserService {

  clienteObs: Subject<any> = new BehaviorSubject<any>('');
  clienteB2BObs: Subject<any> = new BehaviorSubject<any>('');
  ResiObs: Subject<any> = new BehaviorSubject<any>(0);
  Reso: Subject<any> = new BehaviorSubject<any>([]);
  Vettore : boolean = false;
  AlertResi : boolean = true;

  constructor(private http : HttpClient) {  }

  pushCliente(codice,cli){
    this.clienteObs.next({codice: codice, cliente: cli});
  }

  pushClienteB2B(codice,cli){
    this.clienteB2BObs.next({codice: codice, cliente: cli});
  }

  pushResi(resi){
    this.ResiObs.next(resi);
  }   

  getUserInfo() {
    return this.http.get(`${config.apiUrl}/api/Users/UserInfo`) //GET Nome utente
  }

  getB2BGroup() {
    return this.http.get(`${config.apiUrl}/api/Users/ByGroupId/32`) //GET GruppoB2B
  }

  getVettoreGroup() {
    return this.http.get(`${config.apiUrl}/api/Users/ByGroupId/16`) //GET GruppoVettore
  }

}